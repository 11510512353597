/* @import url("https://fonts.googleapis.com/css2?family=Coda+Caption&display=swap"); */

html {
  width: 100%;
  height: 100%;
  padding: 0;
  background: #000;
}

body {
  margin: 0;
}
